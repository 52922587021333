import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/dashboard',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: '/usuarios',
                name: 'Usuários',
                component: () => import('./paginas/usuarios/index.vue')
            },
            {
                path: '/empresas',
                name: 'Empresas',
                component: () => import('./paginas/empresas/index.vue')
            },
            {
                path: '/unidades',
                name: 'Unidades',
                component: () => import('./paginas/unidades/index.vue')
            },
            {
                path: '/areas',
                name: 'Áreas',
                component: () => import('./paginas/areas/index.vue')
            },
            {
                path: '/tipos',
                name: 'Tipos',
                component: () => import('./paginas/tipos/index.vue')
            },
            {
                path: '/faturas/tipos',
                name: 'Tipos Faturas',
                component: () => import('./paginas/tipos_faturas/index.vue')
            },
            {
                path: '/regional',
                name: 'Regional',
                component: () => import('./paginas/regional/index.vue')
            },
            {
                path: '/faturas',
                name: 'Faturas',
                component: () => import('./paginas/faturas/index.vue')
            },
            {
                path: '/divida-ativa',
                name: 'Divida',
                component: () => import('./paginas/divida/index.vue')
            },

            {
                path: '/relatorios/faturas',
                name: 'Relatório de Faturas',
                component: () => import('./paginas/relatorios/faturas.vue')
            },

            {
                path: '/relatorios/guias-pendentes',
                name: 'Relatório de Guia Pendentes',
                component: () => import('./paginas/relatorios/guiasPendentes.vue')
            },

            {
                path: '/relatorios/divida',
                name: 'Relatório de Guia de Dívida',
                component: () => import('./paginas/relatorios/dividas.vue')
            },

            {
                path: '/relatorios/base',
                name: 'Relatório de Base Cadastral',
                component: () => import('./paginas/relatorios/base.vue')
            },
            
            {
                path: '/formlayout',
                name: 'formlayout',
                component: () => import('./components/FormLayoutDemo.vue')
            },
            {
                path: '/input',
                name: 'input',
                component: () => import('./components/InputDemo.vue')
            },
            {
                path: '/floatlabel',
                name: 'floatlabel',
                component: () => import('./components/FloatLabelDemo.vue')
            },
            {
                path: '/invalidstate',
                name: 'invalidstate',
                component: () => import('./components/InvalidStateDemo.vue')
            },
            {
                path: '/button',
                name: 'button',
                component: () => import('./components/ButtonDemo.vue')
            },
            {
                path: '/table',
                name: 'table',
                component: () => import('./components/TableDemo.vue')
            },
            {
                path: '/list',
                name: 'list',
                component: () => import('./components/ListDemo.vue')
            },
            {
                path: '/tree',
                name: 'tree',
                component: () => import('./components/TreeDemo.vue')
            },
            {
                path: '/panel',
                name: 'panel',
                component: () => import('./components/PanelsDemo.vue')
            },
            {
                path: '/overlay',
                name: 'overlay',
                component: () => import('./components/OverlayDemo.vue')
            },
            {
                path: '/media',
                name: 'media',
                component: () => import('./components/MediaDemo.vue')
            },
            {
                path: '/menu',
                component: () => import('./components/MenuDemo.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('./components/menu/PersonalDemo.vue')
                    },
                    {
                        path: '/menu/seat',
                        component: () => import('./components/menu/SeatDemo.vue')
                    },
                    {
                        path: '/menu/payment',
                        component: () => import('./components/menu/PaymentDemo.vue')
                    },
                    {
                        path: '/menu/confirmation',
                        component: () => import('./components/menu/ConfirmationDemo.vue')
                    },
                ],
            },
            {
                path: '/messages',
                name: 'messages',
                component: () => import('./components/MessagesDemo.vue')
            },
            {
                path: '/file',
                name: 'file',
                component: () => import('./components/FileDemo.vue')
            },
            {
                path: '/chart',
                name: 'chart',
                component: () => import('./components/ChartDemo.vue')
            },
            {
                path: '/misc',
                name: 'misc',
                component: () => import('./components/MiscDemo.vue')
            },
            {
                path: '/crud',
                name: 'crud',
                component: () => import('./pages/CrudDemo.vue')
            },
            {
                path: '/timeline',
                name: 'timeline',
                component: () => import('./pages/TimelineDemo.vue')
            },
            {
                path: '/empty',
                name: 'empty',
                component: () => import('./components/EmptyPage.vue')
            },
            {
                path: '/documentation',
                name: 'documentation',
                component: () => import('./components/Documentation.vue')
            },
            {
                path: '/blocks',
                name: 'blocks',
                component: () => import('./components/BlocksDemo.vue')
            },
            {
                path: '/icons',
                name: 'icons',
                component: () => import('./components/IconsDemo.vue')
            }
        ]
    },
    {
        path: '/',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
